<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <ServerError v-if="ServerError" />
    <v-layout wrap justify-center>
      <v-flex px-2 pt-2 xs12 sm12 md12 lg12 xl12 text-center>
        <v-card>
          <v-card-title class="elevation-1">
            Pending Sellers for Approval
            <v-spacer></v-spacer>
            <v-col cols="12" sm="3">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                solo
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="user"
            :items-per-page="count"
            hide-default-footer
            class="elevation-1"
          >
            <template v-slot:item.firstname="{ item }">
              <span>{{ item.firstname }}</span>
              <span> {{ item.lastname }}</span>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="sellerinfo(item._id)">
                mdi-eye
              </v-icon>
               <v-icon small class="mr-2" @click="edit(item._id)">
                mdi-pencil
              </v-icon>
            </template>
          </v-data-table>
          <div class="pt-2" v-if="pages > 1">
            <v-pagination
              :length="pages"
              :total-visible="7"
              v-model="currentPage"
              color="#FF0000"
            ></v-pagination>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      dialogDelete: false,
      search: "",
      currentPage: 1,
      pages: 0,
      count: 20,
      totalData: 0,
      totalRows: 0,
      headers: [
        { text: "Name", value: "firstname" },
        { text: "Username", value: "username" },
        { text: "Phone", value: "phone" },
        { text: "Email", value: "email" },
        { text: "Organization", value: "organization" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      user: [],
    };
  },
  mounted() {
    this.getData();
  },
  watch: {
    search() {
      this.currentPage=1
      this.getData();
    },
     currentPage() {
      this.getData();
    },
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/seller/pendingall",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          count: this.count,
          page: this.currentPage,
          keyword: this.search,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.user = response.data.data;
          this.pages = response.data.pages;
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    sellerinfo(sid) {
      this.$router.push({
        name: "pendingSellerDetails",
        params: { sellerid: sid },
      });
    },
     edit(sid) {
      this.$router.push({ name: "editSeller", params: { sellerid: sid } });
    },
  },
};
</script>